/*
 * @Descripttion:
 * @version:
 * @Author: king
 * @Date: 2023-09-11 14:14:20
 * @LastEditors: wei
 * @LastEditTime: 2023-11-11 15:17:25
 */
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
import { post } from '@/assets/js/http'
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: '',
    avatar: '',
    username: '',
    id: '',
    buttons: [],
    keepAlive: [],
    menu: [],
    userInfo: null,
  },
  getters: {
    is_admin: (state) => {
      return state?.userInfo && state?.userInfo?.admin_role == 1
    }
  },
  mutations: {
    login(state, payload) {
      state.token = payload.token;
    },
    logout(state) {
      state.token = '';
      localStorage.removeItem('token')
      window.location.href = '/'
    },
    setAdminInfo(state, payload) {
      state.avatar = payload.avatar;
      state.username = payload.username;
      state.id = payload.id;
    },
    setButtons(state, payload) {
      state.buttons = payload;
    },
    setKeepAlive(state, alive) {
      state.keepAlive = alive;
    },
    setMenu(state, menu) {
      state.menu = menu;
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    }
  },
  actions: {
    setKeepAlive(ctx, keepalice) {
      ctx.commit('setKeepAlive', keepalice);
    },
    setMenu(state, menu) {
      state.commit('setMenu', menu);
    },
    async getUserInfo() {
      await post({
        url: "/admin/auth.Admin/adminUserInfo"
      }).then(res => {
        this.commit('setUserInfo', res)
      })
    }
  },
  modules: {},
  plugins: [
    createPersistedState({
      key: 'token',
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
